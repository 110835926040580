<template>
  <div>
    <CCard>
      <Device ref="deviceView" @change="onChangedSite" prefix="thcs_"/>
    </CCard>

    <Event ref="eventView" @click="onClickEvent" prefix="thcs_"/>

    <EventDialog ref="eventDialog" />
  </div>
</template>

<script>
import Dashboard from "@/views/btfs/Dashboard"

export default {
  name: "THCSDashboard",
  extends: Dashboard
};
</script>
